label{
  text-align: left;
}

.closeContainer {
  position: fixed;
  top: 12px;
  right: 10px;
  margin: auto;
  width: 50px;
  height: 50px;
  margin-top: 0;
  cursor: pointer;
}

.CloseMenu {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.OpenMenu {
  transform: translateX(0%);
  transition: transform 300ms ease-in-out;
}

.navigationColor {
  z-index: 20;
  position: fixed;
  align-items: flex-start;
  color: white;
  background-image: url('../../../assets/images&icons/world-bg.png');
  background-repeat: no-repeat;
  background-size: 130%;
  background-position: 100px 70px;
  font-size: 20px;

  @media(min-width: 400px) {
    background-position: 170px 110px;
    background-size: 90%;
  }

  @media (min-width: 600px) {
    background-position: 130px 110px;
    background-size: 100%;
    width: 50%;
  }

  @media(min-width: 768px) {
    background-position: 120px 115px;
    background-size: 110%;
    width: 320px;

  }

  @media(min-width: 1024px) {
    background-position: 180px 90px;
    background-size: 95%;
    width: 414px;
  }
}

.navItemsList {
  max-height: 85vh;

  @media (min-height: 100px) and (max-height: 599px) {
    max-height: 35vh;
  }

  @media (max-height: 600px) {
    max-height: 75vh;
  }
}

.navItemsList li {
  display: flex;
  padding: 0.75rem 1.5rem 0.875rem 1.5rem;
  font-weight: 700;
  justify-content: flex-start;
}

.navItemsList::-webkit-scrollbar {
  width: 0;
  height: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.dotsNav {
  min-height: 50vh;
  background-image: url('../../../assets/images&icons//dotsbg.svg');
  background-repeat: repeat;
}

.dotsNavWhite {
  min-height: 90vh;
  background-image: url('../../../assets/images&icons/dotsWhite.png');
  background-repeat: repeat;
  background-size: cover;
  margin-top: .4rem;
}

.hoverBlue:hover {
  cursor: pointer;
  color: #aaa;

}


.link {
  cursor: pointer;
  background-size: 0% 1.5px;
  background-repeat: no-repeat;
  background-image: linear-gradient(white, white);
  /* NOTE: this won't work with background images   */
  transition: all 500ms ease;

}

.link:hover {
  background-position-y: 1.15em;
  background-size: 100% 1.5px;
  transition: all 450ms ease;
}

.leftToRight {
  background-position: left 1.15em;
}

/* Define las animaciones */
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: .7;
  }
}

@keyframes fade-out {
  from {
    opacity: .7;
  }

  to {
    opacity: 0;
  }
}

/* Aplica las animaciones */
.animateFadeIn {
  animation: fade-in 300ms ease-in-out forwards;
}

.animateFadeOut {
  animation: fade-out 300ms ease-in-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;

  }

  to {
    transform: translateX(0);
    opacity: 1;

  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.leftright {
  height: 4px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: #fff;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}

.rightleft {
  height: 4px;
  width: 30px;
  position: absolute;
  margin-top: 24px;
  background-color: #fff;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}

.close {
  margin: 60px 0 0 5px;
  position: absolute;
}

.closeContainer:hover .leftright {
  transform: rotate(0deg);
  background-color: #fff;
}

.closeContainer:hover .rightleft {
  transform: rotate(0deg);
  background-color: #fff;
}

.closeContainer:hover label {
  opacity: 1;
}

nav::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

nav {
  scrollbar-width: none; /* Para Firefox */
}
